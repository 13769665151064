<template>
    <div>
        <template v-if="loading || user">
            <div>
                <div class="ui simple middle aligned grid">
                    <div class="mobile only row">
                        <div class="column">
                            <router-link class="back link" :to="backRoute? backRoute : { name: 'Users' }">
                                <i class="fontello-left"></i>
                            </router-link>
                        </div>
                    </div>
                    <div class="row">
                        <div class="sixteen wide mobile eight wide tablet eight wide computer column">
                            <h3 class="ui header">
                                User Detail
                            </h3>
                        </div>
                        <div class="computer only tablet only eight wide tablet eight wide computer right aligned column">
                            <router-link class="ui small alt button" :to="backRoute? backRoute : { name: 'Users' }">
                                <i class="fontello-left"></i> Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ui hidden divider"></div>
            <div class="ui basic container">
                <div class="ui stackable grid">
                    <div class="seven wide column">
                        <transition name="vue-fade">
                            <div v-if="user">
                                <div class="partial radial shadow" >
                                    <div class="ui segment">
                                        <div class="user card">
                                            <div class="image">
                                                <avatar :initial-class="initialClass" :user="user"/>
                                            </div>
                                            <div class="content">
                                                <h3 class="ui header">
                                                    {{user|displayname}} <label class="ui red label" v-if="user.suspended">Suspended</label>
                                                    <div class="ui hidden fitted clearing divider"></div><div class="ui hidden fitted divider"></div>
                                                    <div class="sub header">{{role}} <template v-if="outlet"> in {{outlet}}</template></div>
                                                </h3>
                                                <div class="ui basic segment" v-if="!is_sm">
                                                    <div class="ui two column center aligned kpi grid">
                                                        <div class="column">
                                                            <div class="ui mini statistic">
                                                                <div class="label">
                                                                    <div class="ui light text" style="text-transform: none;">Consultation</div>
                                                                </div>
                                                                <div class="value"><strong>{{consultation_count}}</strong></div>
                                                            </div>
                                                        </div>
                                                        <div class="column">
                                                            <div class="ui mini statistic">
                                                                <div class="label">
                                                                    <div class="ui light text" style="text-transform: none;">Treatment</div>
                                                                </div>
                                                                <div class="value"><strong>{{prescription_count}}</strong></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ui basic segment" v-if="is_sm">
                                            <div class="ui two column center aligned kpi grid">
                                                <div class="column">
                                                    <div class="ui mini statistic">
                                                        <div class="label">
                                                            <div class="ui light text" style="text-transform: none;">Consultation</div>
                                                        </div>
                                                        <div class="value"><strong>{{consultation_count}}</strong></div>
                                                    </div>
                                                </div>
                                                <div class="column">
                                                    <div class="ui mini statistic">
                                                        <div class="label">
                                                            <div class="ui light text" style="text-transform: none;">Treatment</div>
                                                        </div>
                                                        <div class="value"><strong>{{prescription_count}}</strong></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="ui hidden divider"></div>
                                <div class="action" v-if="user.id !== _user.id">
                                    <div class="partial radial shadow">
                                        <button type="button" class="ui left aligned segment" @click="reactivateUser" v-if="user.suspended">
                                            <div>
                                                <h5 class="ui header">
                                                    Reactivate User
                                                    <pre></pre>
                                                    <p class="sub header">Revoke user suspension from the system</p>
                                                </h5>
                                            </div>
                                            <i class="teal fontello-right icon"></i>
                                        </button>
                                        <button type="button" class="ui left aligned segment" @click="suspendUser" v-else>
                                            <div>
                                                <h5 class="ui header">
                                                    Suspend User
                                                    <pre></pre>
                                                    <p class="sub header">Prevent user from logging in to the system</p>
                                                </h5>
                                            </div>
                                            <i class="teal fontello-right icon"></i>
                                        </button>
                                    </div>
                                    <div class="partial radial shadow">
                                        <button type="button" class="ui left aligned segment" @click="sendTemporaryPassword">
                                            <div>
                                                <h5 class="ui header">
                                                    Send Temporary Password
                                                    <pre></pre>
                                                    <p class="sub header">Send temporary password to user email account</p>
                                                </h5>
                                            </div>
                                            <i class="teal fontello-right icon"></i>
                                        </button>
                                    </div>
                                    <div class="partial radial shadow" v-if="!user.verified">
                                        <button type="button" class="ui left aligned segment" @click="sendVerificationEmail">
                                            <div>
                                                <h5 class="ui header">
                                                    Re-send Verification Email
                                                    <pre></pre>
                                                    <p class="sub header">Re-send verification instruction email to user inbox</p>
                                                </h5>
                                            </div>
                                            <i class="teal fontello-right icon"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="user card" v-else>
                                <div class="image">
                                    <div class="ui placeholder">
                                        <div class="square image"></div>
                                    </div>
                                </div>
                                <div class="content">
                                    <div class="ui placeholder">
                                        <div class="header">
                                            <div class="very short line"></div>
                                            <div class="medium line"></div>
                                        </div>
                                            <div class="paragraph">
                                            <div class="short line"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                    <div class="nine wide column">
                        <transition name="vue-fade">
                            <div v-if="user">
                                <div v-if="user.id !== _user.id">
                                    <h4 class="ui header">
                                        User Info
                                        <label class="ui label"><strong>ID:</strong> {{(`${user.id}`).padStart(7, "0")}}</label>
                                    </h4>
                                    <user-form :id="`userform${_uid}`" class="user-form" :user="user_form" @change:saving="userSavingListener" @save:user="userUpdated"/>
                                    <div class="ui right aligned text">
                                        <button class="ui teal button" type="submit" :form="`userform${_uid}`" :class="{loading: saving}" :disabled="saving">Update</button>
                                    </div>
                                </div>
                                <div class="ui info message" v-else>
                                    <p>
                                        <i class="info circle icon"></i> Please update your information on your profile page or click
                                        <router-link :to="{
                                            name: 'Profile'
                                        }">here</router-link>.
                                    </p>
                                </div>
                            </div>
                            <div class="ui segment" v-else>
                                <div class="ui fluid placeholder">
                                    <div class="header">
                                        <div class="very short line"></div>
                                        <div class="medium line"></div>
                                    </div>
                                    <div class="paragraph">
                                        <div class="short line"></div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="ui error message">
                <i class="info circle icon"></i> No user found.
            </div>
        </template>
    </div>
</template>

<script>
import Avatar from "@/components/Avatar";
import UserForm from "@/views/UserForm";

export default {
    name: "user",
    components: {
        Avatar,
        UserForm
    },
    data() {
        return {
            loading: true,
            id: this.$route.params.id,
            user: null,
            user_form: {},
            outlets: [],
            consultation_count: 0,
            prescription_count: 0,
            saving: false
        };
    },
    created() {
        this.$http.get("outlets").then(({ data }) => {
            this.outlets = data;
        }).catch((error) => {
            console.error(error);

            this.$toasted.error("Unable to list outlets.", {
                duration: 3000
            });
        });

        this.$http.get(`users/${this.id}`).then((response) => {
            this.user = response.data;

            this.user_form = Object.assign({}, this.user);

            return response.data;
        }).catch((error) => {
            console.error(error);
            this.$toasted.error("Unable to load user.", {
                duration: 3000
            });
        }).finally(() => {
            this.loading = false;
        }).then((data) => {
            //get user consultation counts
            if(data) {
                this.$http.head("consultations", {
                    params: {
                        filter: JSON.stringify({
                            created_by: data.id
                        })
                    }
                }).then((response) => {
                    this.consultation_count = response.headers["x-total-count"] || 0;
                }).catch((error) => {
                    console.error(error);
                });

                this.$http.head("prescriptions", {
                    params: {
                        filter: JSON.stringify({
                            created_by: data.id
                        })
                    }
                }).then((response) => {
                    this.prescription_count = response.headers["x-total-count"] || 0;
                }).catch((error) => {
                    console.error(error);
                });
            }
        });
    },
    computed: {
        initialClass() {
            let classes = [];

            const colours = ["red", "orange", "yellow", "olive", "green", "teal", "blue", "violet", "purple", "pink", "brown"];
            if(this.user && !this.user.suspended) {
                const index = this.user.id % colours.length;
                classes.push([colours[index]]);
            }
            return classes;
        },
        role() {
            const role = this.user?.role || "";
            return `${role.charAt(0).toUpperCase()}${role.toLowerCase().slice(1)}`;
        },
        outlet() {
            let outlet = "";
            if(this.user) {
                outlet = (this.outlets.find((_outlet) => {
                    return this.user.outlet === _outlet.id;
                }) || {
                    name: this.user.outlet
                }).name;
            }

            return outlet;
        }
    },
    methods: {
        userSavingListener(saving) {
            this.saving = saving;
        },
        userUpdated(user) {
            Object.assign(this.user, user);
        },
        reactivateUser() {
            this.$confirm({
                title: "Reactivate User",
                message: `Are you sure you want to reactivate user?`,
                button: {
                    yes: "Yes",
                    no: "Cancel"
                },
                callback: (confirmed) => {
                    if(confirmed) {
                        this.saving = true;
                        this.$http.put(`users/${this.user.id}`, {
                            suspended: false
                        }).then((response) => {
                            Object.assign(this.user, response.data);

                            this.$toasted.success(`User reactivated successfully.`, {
                                duration: 3000
                            });
                        }).catch((error) => {
                            console.error(error);

                            this.$toasted.error(`Failed to reactivate user.`, {
                                duration: 3000
                            });
                        }).finally(() => {
                            this.saving = false;
                        });
                    }
                }
            });
        },
        suspendUser() {
            this.$confirm({
                title: "Suspend User",
                message: `Are you sure you want to suspend user?`,
                button: {
                    yes: "Yes",
                    no: "Cancel"
                },
                callback: (confirmed) => {
                    if(confirmed) {
                        this.saving = true;
                        this.$http.put(`users/${this.user.id}`, {
                            suspended: true,
                            suspension_remark: `User suspended by ${this.$options.filters.displayname(this._user)}`
                        }).then((response) => {
                            Object.assign(this.user, response.data);

                            this.$toasted.success(`User suspended successfully.`, {
                                duration: 3000
                            });
                        }).catch((error) => {
                            console.error(error);

                            this.$toasted.error(`Failed to suspend user.`, {
                                duration: 3000
                            });
                        }).finally(() => {
                            this.saving = false;
                        });
                    }
                }
            });
        },
        sendTemporaryPassword() {
            this.$confirm({
                title: "Send Temporary Password",
                message: `Are you sure you want to send temporary password to user?`,
                button: {
                    yes: "Yes",
                    no: "Cancel"
                },
                callback: (confirmed) => {
                    if(confirmed) {
                        this.$http.post("users/temp-password", {
                            email: this.user.username
                        }).finally(() => {
                            this.$toasted.show(`Temporary password sent to ${this.user.username}`, {
                                duration: 3000
                            });
                        });
                    }
                }
            });
        },
        sendVerificationEmail() {
            this.$confirm({
                title: "Send Verification Email",
                message: `Are you sure you want to send verification email to user?`,
                button: {
                    yes: "Yes",
                    no: "Cancel"
                },
                callback: (confirmed) => {
                    if(confirmed) {
                        this.$http.post(`users/${this.user.id}/verification-email`).then(() => {
                            this.$toasted.show(`Request to send verification email to ${this.user.username} is being processed.`, {
                                duration: 3000
                            });
                        }).catch((error) => {
                            console.error(error);
                            this.$toasted.error(`Unable to complete request to send verification email to ${this.user.username}.`, {
                                duration: 3000
                            });
                        });
                    }
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.back.link {
    display: inline-block;
    font-size: 1.35rem;
    margin-bottom: 1rem;
}

.user-form {
    ::v-deep {
        .field > label {
            color: rgba(0, 0, 0, 0.45);
        }
    }
}

.user.card {
    display: flex;
    align-items: flex-start;
    grid-gap: 1.5em;

    > .image {
        border-radius: .28571429rem;
        background: #e4e6eb;
        height: 123px;
        aspect-ratio: 1;

        ::v-deep {
            #Circle {
                display: none;
            }
        }
    }

    > .content {
        flex: 1;
        min-width: 0;
    }

    @media only screen and (max-width: 767px) {
        align-items: center;
    }
}

.action {
    button {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        cursor: pointer;
        margin-bottom: 1em !important;
        box-shadow: none !important;

        .icon {
            margin-left: 0.5em;
            margin-right: 0;
            align-self: center;
        }
    }
}

.ui.kpi.grid {
    background: #f2f5f8;
    border-radius: .28571429rem;
}

.partial.radial.shadow {
    position: relative;
    z-index: 0;

    &:before, &:after {
        content: "";
        position: absolute;
        z-index: -1;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
    }

    &:before {
        top: 3%;
        left: 10%;
        height: 10%;
        width: 80%;
    }

    &:after {
        bottom: 3%;
        right: 10%;
        height: 10%;
        width: 80%;
    }
}
</style>